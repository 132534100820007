import { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUser } from '@clerk/clerk-react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  AltTextResponse,
  DescriptionResponse,
  EtsySessionResponse,
  GeneratedData,
  ChatBotResponse,
  TagsResponse,
  TitleResponse,
  TitlesGenerationResponse,
  AltTextResult,
  DescriptionResult,
  TagsResult,
  EtsyListingResponse,
} from '../common/types';
import useGenericMutation from './useMutation';
import useAlert from './useAlert';
import { ALERT_MESSAGES } from '../constants';
import { RootState, store } from '../redux/store';

import { setSessionID } from '../redux/feature/SessionSlice';
import {
  axiosEtsyInstance,
  axiosStripeInstance,
  useAxiosInterceptors,
  useAxiosStripeInterceptors,
} from '../api/apiInstances';
import { setLisitngId } from '../redux/feature/ListingSlice';
import { ChatComponentRef } from '../components/Home/ChatBot';
import { DragAndDropRef } from '../components/Home/DragNDrop';
import { HistorySelectionRef } from '../components/Home/HistorySelection';
import { setKeywords } from '../redux/feature/KeywordsSlice';
import { UserProfile } from '../common/types/userProfile';
import { CheckoutSessionResponse } from '../common/types/stripeSessions';
import { setStripeData } from '../redux/feature/StripeSlice';
import { setHistoryEnabled } from '../redux/feature/HistoryEnableSlice';

const useHomeState = () => {
  const { user } = useUser();
  useAxiosInterceptors();
  useAxiosStripeInterceptors();
  const sessionID = useSelector((state: RootState) => state.session.sessionID);
  const listingID = useSelector((state: RootState) => state.listing.listingId);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const encodedSessionId = queryParams.get('sessionId');
  const encodedListingId = queryParams.get('listingId');
  const duplicate = queryParams.get('duplicate');
  const locationSessionId = encodedSessionId ? atob(encodedSessionId) : null;
  const locationListingId = encodedListingId ? atob(encodedListingId) : null;

  const [isKeywordTitleGenerateButtonEnabled, setKeywordTitleGenerateButton] =
    useState<boolean>(false);
  const [titleFromUrl, setTitleFromUrl] = useState<string | null>(null);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [isTitleGenerated, setTitleGenerated] = useState<boolean>(false);
  const [isAltCardGenerated, setAltCardGenerated] = useState<boolean>(false);
  const [generatedAltText, setGeneratedAltText] = useState<AltTextResult>();
  const [generatedAltDescription, setGeneratedAltDescription] =
    useState<DescriptionResult>();
  const [generatedAltTags, setGeneratedAltTags] = useState<TagsResult>();
  const [generatedCustomKeywords, setGeneratedCustomKeywords] = useState<
    string[]
  >([]);
  const [imageDescription, setImageDescription] = useState<ChatBotResponse>({
    messages: [],
    chat_state: '',
  });
  const [customInstructions, setCustomInstructions] = useState<string | null>(
    null
  );
  const [imgBase64, setImgBase64] = useState<string | null>(null);
  const [isImageError, setImageError] = useState<boolean>(false);
  const [isImageUploaded, setImageUploaded] = useState<boolean>(false);
  const [reGenerateLoading, setReGenerateLoading] = useState<boolean>(false);
  const [generatedData, setGeneratedData] = useState<GeneratedData>({
    alt_text: [],
    description: [],
    tags: [],
  });
  const [generatedTitles, setGeneratedTitles] = useState<TitleResponse | null>(
    null
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isNewListing, setNewListing] = useState<boolean>(false);
  const [listingConsumedModalOpened, setListingConsumedModalOpened] =
    useState<boolean>(false);
  const [purchaseTrialModalOpened, setPurchaseTrialModalOpened] =
    useState<boolean>(false);
  const [consumedTrialModalOpened, setConsumedTrialModalOpened] =
    useState<boolean>(false);
  const [plansModalOpened, setPlansModalOpened] = useState(false);
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [loadingSession, setLoadingSession] = useState<boolean>(false);
  const [disableAllButtons, setDisableAllButtons] = useState<boolean>(false);
  const [listingName, setListingName] = useState<string>('');
  const [disableAltGenerationButton, setDisableAltGenerationButton] =
    useState<boolean>(false);

  const [isDuplicate, setIsDuplicate] = useState<boolean>(false);
  const [listingNotCompleteModal, setListingNotCompleteModal] =
    useState<boolean>(false);
  const [bypassIncompleteListingCheck, setBypassIncompleteListingCheck] =
    useState<boolean>(false);
  const [remainingListings, setRemainingListings] = useState<number>(0);
  const [loadingRemainingListings, setLoadingRemainingListings] =
    useState<boolean>(false);
  const [loadedChatMessages, setLoadedChatMessages] = useState<ChatBotResponse>(
    {
      messages: [],
      chat_state: '',
    }
  );
  const [isNewListingLoading, setIsNewListingLoading] =
    useState<boolean>(false);
  const [showScrollToBottomButton, setShowScrollToBottomButton] =
    useState<boolean>(false);
  const [selectedTitleIndex, setSelectedTitleIndex] = useState<number>(0);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] =
    useState<boolean>(false);
  const [isFeedBackAllowed, setIsFeedBackAllowed] = useState<boolean>(false);
  const [isDuplicationAllowed, setIsDuplicationAllowed] =
    useState<boolean>(false);
  const { alert, setAlert, closeAlert } = useAlert();
  const dispatch = useDispatch();
  const { mutateAsync: startSession } = useGenericMutation<EtsySessionResponse>(
    '/session',
    'POST'
  );

  const { mutateAsync: getTitlesAndKeywords, isError: titleKeywordsError } =
    useGenericMutation<TitlesGenerationResponse>(
      `/generate/${listingID}/title`,
      'POST'
    );

  const { mutateAsync: generateAltText, isError: altTextError } =
    useGenericMutation<AltTextResponse>(
      `/generate/${listingID}/alt_text`,
      'POST'
    );

  const { mutateAsync: generateDescription, isError: descriptionError } =
    useGenericMutation<DescriptionResponse>(
      `/generate/${listingID}/description`,
      'POST'
    );

  const { mutateAsync: generateTags, isError: tagError } =
    useGenericMutation<TagsResponse>(`/generate/${listingID}/tags`, 'POST');

  const { mutateAsync: updateUserProfile } = useGenericMutation<any>(
    '/users/profile',
    'PUT',
    axiosStripeInstance
  );

  const { mutateAsync: createCheckoutSession } =
    useGenericMutation<CheckoutSessionResponse>(
      '/subscription/create-checkout-session',
      'POST',
      axiosStripeInstance
    );

  const navigate = useNavigate();

  const successPath = `/stripe-success?params=${btoa('fromStripe=true')}`;
  const cancelPath = `/stripe-cancel?params=${btoa('fromStripe=true')}`;

  useEffect(() => {
    if (
      sessionID !== null &&
      listingID !== null &&
      duplicate === null &&
      locationSessionId === null
    ) {
      fetchSessionViewData(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const handleScroll = () => {
      setShowScrollToBottomButton(window.scrollY > 300);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const { isCheckingOut } = useSelector((state: RootState) => state.checkOut);

  useEffect(() => {
    if (loadingSession) {
      console.log('loadingSession', loadingSession);
    }
  }, [loadingSession]);

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (user?.id) {
        try {
          const response = await axiosStripeInstance.get<UserProfile>(
            `/users/${user.id}`
          );
          setUserProfile(response.data);
        } catch (error: any) {
          const message = error.response?.data?.message;
          setAlert({
            isSuccess: false,
            message: message || ALERT_MESSAGES.USER_PROFILE_LOAD_FAILED,
          });
        }
      }
    };

    fetchUserProfile();
  }, [setAlert, user?.id]);

  useEffect(() => {
    if (locationSessionId && duplicate) {
      setLoadingSession(true);
      fetchDuplicateSessionData();
    } else if (locationSessionId && !duplicate) {
      setLoadingSession(true);
      fetchSessionViewData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duplicate]);

  useEffect(() => {
    if (altTextError) {
      setAlert({
        isSuccess: false,
        message: ALERT_MESSAGES.ALT_TEXT_NOT_GENERATED,
      });
    } else if (descriptionError) {
      setAlert({
        isSuccess: false,
        message: ALERT_MESSAGES.DESCRIPTION_NOT_GENERATED,
      });
    } else if (tagError) {
      setAlert({
        isSuccess: false,
        message: ALERT_MESSAGES.TAGS_NOT_GENERATED,
      });
    } else if (titleKeywordsError) {
      setAlert({
        isSuccess: false,
        message: ALERT_MESSAGES.TITLES_NOT_GENERATED,
      });
    }
  }, [
    // customKeywordsError,
    altTextError,
    descriptionError,
    tagError,
    titleKeywordsError,
    setAlert,
  ]);

  useEffect(() => {
    setLoadingRemainingListings(true);
    const fetchSubscription = async () => {
      try {
        const subscriptionResponse = await axiosStripeInstance.get<any>(
          `/subscription/all-subscriptions?userId=${user?.id}`
        );
        const activeSubscription = subscriptionResponse.data.basicPlans.find(
          (subscription: any) => subscription.status === 'active'
        );
        setRemainingListings(activeSubscription?.remainingListings);
      } catch (error: any) {
        const message = error.response?.data?.message;
        setAlert({
          isSuccess: false,
          message: message || ALERT_MESSAGES.SUBSCRIPTION_LOAD_FAILED,
        });
      } finally {
        setLoadingRemainingListings(false);
      }
    };
    fetchSubscription();
  }, [user?.id, setAlert]);

  const handleFeedback = async () => {
    const feedbackListingId = store.getState().listing.listingId;
    const url = new URL(window.location.href);
    url.searchParams.set('feedback', 'true');
    window.history.pushState({}, '', url.toString());
    try {
      const feedbackResponse = await axiosEtsyInstance.post<ChatBotResponse>(
        `/chat/${feedbackListingId}/feedback`
      );
      setLoadedChatMessages({
        messages: feedbackResponse.data.messages.map(msg => ({
          role: msg.role as 'user' | 'assistant',
          content: msg.content,
        })),
        chat_state: feedbackResponse.data.chat_state,
      });

      setDisableAllButtons(false);
      setTitleGenerated(false);

      setIsFeedbackModalOpen(false);
      scrollToTop();
    } catch (error: any) {
      const message = error.response?.data?.message;
      setAlert({
        isSuccess: false,
        message: message || ALERT_MESSAGES.FEEDBACK_FAILED,
      });
    }
  };

  const handleStartTrial = async () => {
    try {
      const trialResponse =
        await axiosStripeInstance.get<any>(`/subscription/trial`);

      const response = await createCheckoutSession({
        planId: trialResponse?.data?.id,
        userId: user?.id,
        successUrl: `${window.location.origin}${successPath}`,
        cancelUrl: `${window.location.origin}${cancelPath}`,
        planType: trialResponse?.data.type,
      });
      dispatch(
        setStripeData({
          stripeUrl: response.paymentUrl,
          selectedPlan: trialResponse?.data?.id,
        })
      );
      window.open(response.paymentUrl, '_blank');
    } catch (error: any) {
      const message =
        error.response?.data?.detail || error.response?.data?.message;
      setAlert({
        isSuccess: false,
        message: message || ALERT_MESSAGES.START_TRIAL_FAILED,
      });
    }
  };

  const fetchDuplicateSessionData = useCallback(
    async (
      listingId: string = locationListingId || '',
      fromChat: boolean = false
    ) => {
      resetAll();
      try {
        const duplicateResponse = await axiosEtsyInstance.post(
          `/listing/${listingId}/duplicate`
        );
        const duplicateResponseData =
          await axiosEtsyInstance.get<EtsyListingResponse>(
            `/listing/${duplicateResponse.data.listing._id}`
          );

        setLoadingSession(false);

        dispatch(setSessionID(duplicateResponse.data.session_id));
        dispatch(setLisitngId(duplicateResponse.data.listing._id));

        handleSetDuplicateLoadSession(duplicateResponseData.data);
        if (!fromChat) {
          navigate('/home', { replace: true });
        }
        setReGenerateLoading(false);
      } catch (error: any) {
        const message = error.response?.data?.detail;
        setAlert({
          isSuccess: false,
          message: message || ALERT_MESSAGES.SESSION_LOAD_FAILED,
        });
      }
    },
    [dispatch, locationListingId, navigate, setAlert]
  );

  const handleSetDuplicateLoadSession = (
    duplicateResponse: EtsyListingResponse
  ) => {
    setNewListing(true);
    if (duplicateResponse.chat) {
      setLoadedChatMessages({
        messages: duplicateResponse.chat.messages.map(msg => ({
          role: msg.role as 'user' | 'assistant',
          content: msg.content,
        })),
        chat_state: duplicateResponse.chat.chat_state,
      });
    }
    setKeywordTitleGenerateButton(false);
    setImgBase64(duplicateResponse.listing.img_url);

    setListingName(duplicateResponse.listing?.name || '');
    setIsDuplicate(true);
    setLoadingSession(false);
  };

  const fetchSessionViewData = useCallback(
    async (flag: boolean = false) => {
      try {
        const sessionEndpointId = locationSessionId
          ? locationSessionId
          : sessionID;
        const listingEndpointId = locationListingId
          ? locationListingId
          : listingID;

        if (!sessionEndpointId || !listingEndpointId) {
          console.error('Missing required IDs for session view');
          return;
        }

        const [generationResponse] = await Promise.all([
          axiosEtsyInstance.get<EtsyListingResponse>(
            `/listing/${listingEndpointId}`
          ),
        ]);

        if (flag && generationResponse.data.listing.alt_text) {
          dispatch(setSessionID(null));
          dispatch(setLisitngId(null));
          return;
        }

        dispatch(setSessionID(sessionEndpointId));
        dispatch(setLisitngId(listingEndpointId));

        if (generationResponse.data.listing.parent_listing_id !== null) {
          setIsDuplicate(true);
        }

        if (generationResponse.data.listing.title === null) {
          if (generationResponse.data.listing.is_completed) {
            setDisableButton(true);
          } else {
            setDisableButton(false);
          }
        }

        if (
          generationResponse.data.listing.title ||
          generationResponse.data.listing.title != null
        ) {
          setDisableAllButtons(true);
        }
        const loadedGeneration = generationResponse.data;
        handleSetViewSession(loadedGeneration);

        navigate('/home', { replace: true });
      } catch (error: any) {
        const message = error.response?.data?.detail;
        setAlert({
          isSuccess: false,
          message: message || ALERT_MESSAGES.SESSION_LOAD_FAILED,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      locationSessionId,
      sessionID,
      locationListingId,
      listingID,
      dispatch,
      navigate,
      setAlert,
      setIsDuplicate,
    ]
  );

  const handleSetViewSession = (loadedGeneration: EtsyListingResponse) => {
    setNewListing(true);
    const isTitleGenerated = Boolean(
      (loadedGeneration.listing.title?.length || 0) > 0 ||
        loadedGeneration.listing.title_selection ||
        0
    );
    setTitleGenerated(isTitleGenerated);
    const isAltCardItemsGenerated =
      (loadedGeneration.listing.alt_text?.length ?? 0) > 0 &&
      (loadedGeneration.listing.description?.length ?? 0) > 0 &&
      (loadedGeneration.listing.tags?.length ?? 0) > 0;

    if (isAltCardItemsGenerated) {
      setDisableAltGenerationButton(true);
    }

    if (loadedGeneration.listing.keywords) {
      setGeneratedCustomKeywords(loadedGeneration.listing.keywords);
      setAltCardGenerated(true);
      dispatch(setKeywords(loadedGeneration.listing.keywords));
    }

    if (isTitleGenerated) {
      setTitleGenerated(true);
      setGeneratedTitles({
        title: loadedGeneration.listing.title,
        title_selection: loadedGeneration.listing.title_selection,
      });
    }

    setGeneratedAltText({
      alt_text: loadedGeneration.listing.alt_text || [],
      selected: selectedTitleIndex,
    });

    setGeneratedAltDescription({
      description: loadedGeneration.listing.description || [],
      selected: selectedTitleIndex,
    });

    setGeneratedAltTags({
      tags: loadedGeneration.listing.tags || [],
      selected: selectedTitleIndex,
    });

    if (loadedGeneration.chat) {
      setLoadedChatMessages({
        messages: loadedGeneration.chat.messages.map(msg => ({
          role: msg.role as 'user' | 'assistant',
          content: msg.content,
        })),
        chat_state: loadedGeneration.chat.chat_state,
      });
    }

    if (loadedGeneration.chat.chat_state === 'complete') {
      setKeywordTitleGenerateButton(true);
    }
    setImgBase64(loadedGeneration.listing.img_url || null);
    setListingName(loadedGeneration.listing.name || '');
    setLoadingSession(false);
  };

  const startEtsySession = async () => {
    try {
      const response = await startSession({});
      setListingName(response.listing.name || 'Untitled');
      store.dispatch(setSessionID(response.session.id));
      store.dispatch(setLisitngId(response.listing.id));
      setSelectedTitleIndex(response.listing.title_selection);
      await updateUserProfile({ trialCompleted: true });
    } catch (error: any) {
      const profileResponse = await axiosStripeInstance.get<UserProfile>(
        `/users/${user?.id}`
      );
      const { trialCompleted, planPurchased } =
        profileResponse.data || userProfile;

      if (!trialCompleted) {
        setPurchaseTrialModalOpened(true);
      } else if (trialCompleted && !planPurchased) {
        setConsumedTrialModalOpened(true);
      } else if (planPurchased) {
        setListingConsumedModalOpened(true);
      }
    } finally {
      setNewListing(true);
    }
  };

  const handleImageDescription = (
    description: ChatBotResponse,
    imgBase64: string | null,
    isError?: boolean
  ) => {
    console.log('description in handleImageDescription', description);
    setImageDescription(description);
    setImgBase64(imgBase64);
    isError && setImageError(true);
  };

  const notifyChatBot = (flag: boolean) => {
    setImageUploaded(flag);
  };

  const handleChatEnd = (endBool: boolean) => {
    setKeywordTitleGenerateButton(endBool);
    setDisableButton(false);
  };

  const handleGenerateGeneration = async (
    retryCount = 0,
    maxRetries = 3
  ): Promise<void> => {
    setIsLoading(true);
    try {
      const titlesAndKeywordsResponse = await getTitlesAndKeywords({});
      if (titlesAndKeywordsResponse.keywords) {
        setGeneratedCustomKeywords(titlesAndKeywordsResponse.keywords);
        setAltCardGenerated(true);
        dispatch(setKeywords(titlesAndKeywordsResponse.keywords));
      }

      if (titlesAndKeywordsResponse.titles) {
        setTitleGenerated(true);
        setGeneratedTitles({
          title: titlesAndKeywordsResponse.titles,
          title_selection: selectedTitleIndex,
        });
        setAlert({
          isSuccess: true,
          message: ALERT_MESSAGES.TITLE_GENERATED_SUCCESS,
        });
      }
      setDisableButton(true);
      // setIsFeedBackAllowed(true);
    } catch (error: any) {
      if (retryCount < maxRetries) {
        const backoffDelay = Math.pow(2, retryCount) * 1000; // 1s, 2s, 4s
        await new Promise(resolve => setTimeout(resolve, backoffDelay));

        return handleGenerateGeneration(retryCount + 1, maxRetries);
      }

      const message = error.response?.data?.detail;
      setAlert({
        isSuccess: false,
        message: message || ALERT_MESSAGES.GENERATION_GENERATION_FAILED,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleAltCardGeneration = async () => {
    try {
      const altTextResponse = await generateAltText({});
      setAltCardGenerated(true);
      setGeneratedAltText({
        alt_text: altTextResponse.alt_text,
        selected: selectedTitleIndex,
      });

      const descriptionResponse = await generateDescription({});
      setGeneratedAltDescription({
        description: descriptionResponse.description,
        selected: selectedTitleIndex,
      });

      const generateTagsWithRetry = async (
        retryCount = 0,
        maxRetries = 3
      ): Promise<void> => {
        try {
          const tagsResponse = await generateTags({});
          setGeneratedAltTags({
            tags: tagsResponse.tags,
            selected: selectedTitleIndex,
          });
        } catch (error: any) {
          if (retryCount < maxRetries) {
            const backoffDelay = Math.pow(2, retryCount) * 1000;
            await new Promise(resolve => setTimeout(resolve, backoffDelay));
            return generateTagsWithRetry(retryCount + 1, maxRetries);
          }
          throw error;
        }
      };

      await generateTagsWithRetry();
      setDisableAltGenerationButton(true);
      dispatch(setHistoryEnabled(true));
      const listingId = store.getState().listing.listingId;
      const generationResponse =
        await axiosEtsyInstance.get<EtsyListingResponse>(
          `/listing/${listingId}`
        );
      setIsDuplicationAllowed(
        generationResponse.data.listing.can_be_duplicated
      );
      setIsFeedBackAllowed(true);
    } catch (error: any) {
      const message = error.response?.data?.detail;
      setAlert({
        isSuccess: false,
        message: message || ALERT_MESSAGES.ALT_CARD_DATA_GENERATION_FAILED,
      });
    } finally {
      setAltCardGenerated(true);
    }
  };

  const chatRef = useRef<ChatComponentRef>(null);
  const dragAndDropRef = useRef<DragAndDropRef>(null);
  const selectionRef = useRef<HistorySelectionRef>(null);

  const resetAll = () => {
    setKeywordTitleGenerateButton(false);
    setTitleGenerated(false);
    setAltCardGenerated(false);
    setGeneratedAltText(undefined);
    setGeneratedAltDescription(undefined);
    setGeneratedAltTags(undefined);
    setGeneratedCustomKeywords([]);
    setCustomInstructions('');
    setImgBase64(null);
    setImageError(false);
    setImageUploaded(false);
    setGeneratedData({
      alt_text: [],
      description: [],
      tags: [],
    });
    setGeneratedTitles(null);
    setIsLoading(false);
    setAlert(null);
    dispatch(setHistoryEnabled(false));
    setDisableAllButtons(false);
    setIsDuplicate(false);
    setListingName('');
    setTitleGenerated(false);
    setDisableAllButtons(false);
    setDisableAltGenerationButton(false);
    setIsDuplicationAllowed(false);
    setIsFeedBackAllowed(false);
    if (chatRef.current) {
      chatRef.current.resetChat();
    }
    if (dragAndDropRef.current) {
      dragAndDropRef.current.resetDragAndDrop();
    }
  };

  const checkSubscriptionAndCreateListing = async (
    shouldReset: boolean = false
  ) => {
    setIsNewListingLoading(true);

    try {
      const subscriptionResponse = await axiosStripeInstance.get<any>(
        `/subscription/all-subscriptions?userId=${user?.id}`
      );
      const activeSubscription = subscriptionResponse.data.basicPlans.find(
        (subscription: any) => subscription?.status === 'active'
      );
      if (!activeSubscription || activeSubscription?.remainingListings === 0) {
        setListingConsumedModalOpened(true);
      } else if (
        activeSubscription &&
        activeSubscription.remainingListings > 0
      ) {
        resetAll();
        dispatch(setSessionID(null));
        await startEtsySession();
      }
    } catch (error: any) {
      const message = error.response?.data?.detail;
      setAlert({
        isSuccess: false,
        message: message || ALERT_MESSAGES.GENERATION_FAILED,
      });
    } finally {
      setIsNewListingLoading(false);
    }
  };

  const createNewListing = () => checkSubscriptionAndCreateListing(false);

  const createNewListingDuringSession = async (bypass: boolean) => {
    if (!generatedAltTags && !bypass) {
      setListingNotCompleteModal(true);
      return;
    }
    await checkSubscriptionAndCreateListing(true);
    setBypassIncompleteListingCheck(false);
  };

  return {
    isKeywordTitleGenerateButtonEnabled,
    isTitleGenerated,
    disableButton,
    titleFromUrl,
    isAltCardGenerated,
    imageDescription,
    customInstructions,
    imgBase64,
    isImageUploaded,
    generatedData,
    generatedTitles,
    isLoading,
    alert,
    chatRef,
    dragAndDropRef,
    selectionRef,
    setDisableButton,
    setTitleFromUrl,
    setCustomInstructions,
    handleImageDescription,
    notifyChatBot,
    handleChatEnd,
    handleGenerateGeneration,
    handleAltCardGeneration,
    handleSetDuplicateLoadSession,
    resetAll,
    setAlert,
    closeAlert,
    isNewListing,
    setNewListing,
    loadingSession,
    createNewListing,
    generatedAltText,
    generatedAltDescription,
    generatedAltTags,
    generatedCustomKeywords,
    startEtsySession,
    isImageError,
    listingConsumedModalOpened,
    setListingConsumedModalOpened,
    plansModalOpened,
    setPlansModalOpened,
    purchaseTrialModalOpened,
    setPurchaseTrialModalOpened,
    consumedTrialModalOpened,
    setConsumedTrialModalOpened,
    handleStartTrial,
    createNewListingDuringSession,
    isCheckingOut,
    fetchSessionViewData,
    disableAllButtons,
    listingName,
    disableAltGenerationButton,
    isDuplicate,
    listingNotCompleteModal,
    setListingNotCompleteModal,
    setBypassIncompleteListingCheck,
    bypassIncompleteListingCheck,
    remainingListings,
    loadingRemainingListings,
    loadedChatMessages,
    isNewListingLoading,
    showScrollToBottomButton,
    scrollToTop,
    setSelectedTitleIndex,
    selectedTitleIndex,
    isFeedbackModalOpen,
    setIsFeedbackModalOpen,
    handleFeedback,
    fetchDuplicateSessionData,
    isFeedBackAllowed,
    isDuplicationAllowed,
    reGenerateLoading,
    setReGenerateLoading,
  };
};
export default useHomeState;
