/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
  useRef,
} from 'react';

import { Icons } from '../../../helpers/icons';
import { InputField } from '../../shared/ui/InputField';
import { Button } from '../../shared/ui/Button';
import { Message, ChatBotResponse } from '../../../common/types';
import useGenericMutation from '../../../CustomHooks/useMutation';
import Loader from '../../shared/ui/ChatLoader';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import useAlert from '../../../CustomHooks/useAlert';
import { useAxiosInterceptors } from '../../../api/apiInstances';
import Alert from '../../shared/ui/Alert';

interface ChatComponentProps {
  imageDescription: ChatBotResponse;
  isImageUploaded: boolean;
  customInstructions: string | null;
  isImageError?: boolean;
  isChatBotDisabled: boolean;
  setCustomInstructions: (value: string | null) => void;
  handleChatEnd: (value: boolean) => void;
  loadedChatMessages: ChatBotResponse;
}

export interface ChatComponentRef {
  resetChat: () => void;
}

const ChatComponent = forwardRef<ChatComponentRef, ChatComponentProps>(
  (
    {
      imageDescription,
      isImageUploaded,
      handleChatEnd,
      isImageError,
      isChatBotDisabled,
      loadedChatMessages,
    },
    ref
  ) => {
    useAxiosInterceptors();
    const { setAlert, alert, closeAlert } = useAlert();

    const [input, setInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isChatComplete, setIsChatComplete] = useState(isChatBotDisabled);
    const [messages, setMessages] = useState<Message[]>([
      { role: 'assistant', content: 'Hello! What item are you selling?' },
    ]);
    const etsyListingId = useSelector(
      (state: RootState) => state.listing.listingId
    );
    const messagesContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      setIsChatComplete(isChatBotDisabled);
    }, [isChatBotDisabled]);

    useEffect(() => {
      if (loadedChatMessages.messages.length > 0) {
        const mappedMessages: Message[] = loadedChatMessages.messages.map(
          chatMessage => {
            return {
              role: chatMessage.role as 'user' | 'assistant',
              content: chatMessage.content,
            };
          }
        );
        if (loadedChatMessages.chat_state === 'complete') {
          setIsChatComplete(true);
        } else if (loadedChatMessages.chat_state === 'feedback') {
          setIsChatComplete(false);
        }
        setMessages(mappedMessages);
      }
    }, [loadedChatMessages]);

    const { mutateAsync: getChatResponse, isError: chatBotError } =
      useGenericMutation<ChatBotResponse>(`/chat/${etsyListingId}`, 'POST');

    const appendMessage = (newMessage: Message[]) => {
      setMessages(prevMessages => [...prevMessages, ...newMessage]);
    };

    const handleSendMessage = async () => {
      if (!input.trim() || isChatComplete) return;

      const userMessage: Message[] = [{ role: 'user', content: input }];
      appendMessage(userMessage);
      setInput('');
      setIsLoading(true);

      const maxRetries = 2;
      let currentTry = 0;

      while (currentTry <= maxRetries) {
        try {
          const formData = {
            type: 'text',
            content: input,
          };

          const response = await getChatResponse(formData);

          setIsChatComplete(response.chat_state === 'complete');
          handleChatEnd(response.chat_state === 'complete');

          appendMessage(
            response.messages.map(message => ({
              role: 'assistant',
              content: message.content,
            }))
          );

          break;
        } catch (error: any) {
          currentTry++;

          if (currentTry <= maxRetries) {
            console.log(`Retry attempt ${currentTry} of ${maxRetries}`);
            continue;
          }

          const errorMessage =
            error.response?.data?.detail ||
            'Sorry, there was a server error. Please try again later.';

          setAlert({
            isSuccess: false,
            message: errorMessage,
          });

          appendMessage([
            {
              role: 'assistant',
              content: errorMessage,
            },
          ]);
          console.error('Error in chatbot after all retries:', error);
        }
      }

      setIsLoading(false);
    };

    useEffect(() => {
      if (!imageDescription) return;

      const botMessage: Message[] = imageDescription.messages.map(message => {
        return {
          role: 'assistant',
          content: message.content,
        };
      });

      botMessage.forEach(message => appendMessage([message]));
    }, [imageDescription]);

    useEffect(() => {
      if (isImageUploaded) {
        appendMessage([{ role: 'user', content: 'Uploaded image' }]);
        appendMessage([
          { role: 'assistant', content: 'Processing your image' },
        ]);
      }
    }, [isImageUploaded]);

    useEffect(() => {
      messagesContainerRef.current?.scrollTo({
        top: messagesContainerRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }, [messages]);

    useImperativeHandle(ref, () => ({
      resetChat() {
        setMessages([
          { role: 'assistant', content: 'Hello! What item are you selling?' },
        ]);
        setInput('');
        setIsLoading(false);
      },
    }));

    const formatMessageContent = (content: string) => {
      return content.split('\n').map((line, index) => (
        <span key={index}>
          {line}
          <br />
        </span>
      ));
    };

    return (
      <>
        {alert && (
          <Alert
            isSuccess={alert.isSuccess}
            message={alert.message}
            onClose={closeAlert}
          />
        )}
        <div className="w-full p-4 border rounded-lg bg-gray-50 bg-opacity-10">
          <h2 className="text-lg md:text-xl font-bold mb-4 border-b pb-3">
            Talk with Chatbot
          </h2>
          <div className="h-96 overflow-y-auto pr-5" ref={messagesContainerRef}>
            {messages.map((message, index) => (
              <div
                key={index}
                className={`flex my-4 ${message.role === 'user' ? 'justify-start' : 'justify-end'}`}
              >
                <div className="flex items-center">
                  {message.role === 'user' ? (
                    <>
                      <Icons.Profile className="w-6 h-6 mr-2 flex-shrink-0" />
                      <div className="p-2 text-xs md:text-sm rounded-md bg-white text-gray-400 border">
                        {formatMessageContent(message.content)}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="p-2 text-xs md:text-sm rounded-md bg-white text-gray-400 border">
                        {formatMessageContent(message.content)}
                      </div>
                      <Icons.ChatBot className="w-6 h-6 ml-2 flex-shrink-0" />
                    </>
                  )}
                </div>
              </div>
            ))}
            {isLoading && (
              <div className="flex justify-end my-3">
                <Loader />
                <Icons.ChatBot className="w-6 h-6 ml-2 flex-shrink-0" />
              </div>
            )}
          </div>
          <div className="mt-2">
            <InputField
              placeholder={
                isChatComplete ? 'Chat completed' : 'Write a message...'
              }
              value={input}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setInput(e.target.value)
              }
              onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) =>
                e.key === 'Enter' && handleSendMessage()
              }
              inputClassName="px-3 text-sm md:text-base mr-20"
              disabled={isChatComplete}
              endIcon={
                <Button
                  label={'Send'}
                  size="medium"
                  disabled={isLoading || isChatComplete}
                  className={`rounded-sm px-5 ${
                    isLoading || isChatComplete
                      ? 'bg-[#808080] text-[#B7B7B7]'
                      : 'bg-etsyPrimary text-white'
                  }`}
                  onClick={handleSendMessage}
                />
              }
            />
          </div>
          {/* <InputCard
          title={'Insert all custom instructions (Optional)'}
          placeHolder="Use this section to give the bot any additional information you would like incorporated into this listing..."
          initialValue={customInstructions! || ''}
          headingClassName="text-base md:text-base"
          inputClassName="placeholder-gray-500 text-sm h-[330px]"
          className="pt-6 "
          areaClassName="min-h-[200px] md:min-h-fit"
          onChange={setCustomInstructions}
          showCopyButton={false}
          showCharacterCount={false}
        /> */}
        </div>
      </>
    );
  }
);

export default ChatComponent;
