import React, { useState, useEffect } from 'react';

import Loader from '../../components/shared/layout/Loader';
import Card from '../../components/shared/ui/Card';
import { Button } from '../../components/shared/ui/Button';
import { Icons } from '../../helpers/icons';
import { useNavigate } from 'react-router-dom';
import {
  axiosEtsyInstance,
  useAxiosInterceptors,
} from '../../api/apiInstances';
import { formatDate } from '../../helpers/utils';
import ConfirmationModal from '../AccountDetails/ConfirmationModal';
import Alert from '../../components/shared/ui/Alert';
import useAlert from '../../CustomHooks/useAlert';

export interface SessionHistoryItemProps {
  id: string;
  session_id: string;
  title: string;
  updated_at: string;
  name: string;
  img_url?: string;
  version: number;
  created_at: string;
  is_completed: boolean;
  parent_listing_id: string | null;
  is_archived: boolean;
}

interface GroupedListingMetadata {
  listings: SessionHistoryItemProps[];
  activeDuplicateId: string | null;
  canDuplicate: boolean;
}

const SessionHistoryItem: React.FC<
  SessionHistoryItemProps & {
    onDelete: (id: string) => Promise<void>;
    index: number;
  }
> = ({
  id,
  session_id,
  title,
  updated_at,
  name,
  img_url,
  is_completed,
  onDelete,
  index,
}) => {
  useAxiosInterceptors();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const handleDuplicateView = () => {
    const encodedSessionId = btoa(session_id);
    const encodedLocationId = btoa(id);

    navigate(
      `/home?sessionId=${encodedSessionId}&listingId=${encodedLocationId}&title=${title}`,
      {
        replace: true,
      }
    );
  };

  let label = 'View';
  if (!is_completed) {
    label = 'Continue';
  }

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      await onDelete(id);
      closeModal();
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <div className="md:w-full border shadow-[0px_0px_12px_0px_rgba(74,131,184,0.12)] md:rounded-2xl rounded-xl bg-white md:p-6 p-3 mb-4 relative">
      <div className="flex justify-between items-center my-3">
        <div className="flex md:flex-row flex-col md:items-center">
          {img_url ? (
            <img
              src={img_url}
              alt={title}
              className="w-16 h-16 object-contain rounded-md mr-4"
            />
          ) : (
            <Icons.HistoryPlaceholder className="w-16 h-16 mr-4 text-gray-300" />
          )}
          <div>
            <div className="font-semibold md:text-lg md:max-w-[400px] text-sm text-[#3f3f3f] mr-5">
              {name &&
              name !== 'Untitled' &&
              name !== 'Untitled - Version 1' &&
              name !== 'Untitled - Version 2' &&
              name !== 'Untitled - Version 3'
                ? name
                : !is_completed
                  ? 'Uncompleted Listing'
                  : title}
            </div>
            <div className="flex flex-row items-center md:mt-1 mt-2">
              <Icons.Clock width={16} className="mr-1" />
              <p className="text-gray-600 text-sm">{formatDate(updated_at)}</p>
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-2">
          <Button
            label={label}
            variant="primary"
            className={`py-2 md:px-10 px-5 rounded-sm border-etsyPrimary text-etsyPrimary md:text-base text-sm history-item-btn-${index}`}
            onClick={handleDuplicateView}
          />
          <Icons.Trash
            className="w-8 h-8 self-center cursor-pointer"
            onClick={openModal}
          />
        </div>
        <ConfirmationModal
          icon={'etSEOLogo.png'}
          isOpen={isOpen}
          closeModal={closeModal}
          onConfirm={handleDelete}
          onCancel={closeModal}
          title="Delete Listing"
          description="Are you sure you want to delete this listing?"
          confirmLabel="Delete"
          cancelLabel="Cancel"
          isLoading={isDeleting}
        />
      </div>
    </div>
  );
};

const ListingGroup: React.FC<{
  sessionId: string;
  groupData: GroupedListingMetadata;
  groupIndex: number;
  onDelete: (id: string) => Promise<void>;
}> = ({ sessionId, groupData, groupIndex, onDelete }) => {
  const navigate = useNavigate();
  const { listings, activeDuplicateId, canDuplicate } = groupData;
  const { alert, setAlert, closeAlert } = useAlert();

  const handleDuplicate = () => {
    const incompleteListing = groupData.listings.find(
      listing => !listing.is_completed
    );
    if (incompleteListing) {
      setAlert({
        isSuccess: false,
        message:
          'Previous listing should be completed to generate a new listing',
      });
      return;
    }
    if (!activeDuplicateId) return;
    const encodedSessionId = btoa(sessionId);
    const encodedLocationId = btoa(activeDuplicateId);
    const title = listings.find(l => l.id === activeDuplicateId)?.title || '';

    navigate(
      `/home?sessionId=${encodedSessionId}&listingId=${encodedLocationId}&title=${title}&duplicate=true`,
      {
        replace: true,
      }
    );
  };

  const activeListings = listings.filter(listing => !listing.is_archived);

  return (
    <div className="mb-8">
      <div className="flex justify-between items-center mb-4">
        {alert && (
          <Alert
            isSuccess={alert.isSuccess}
            message={alert.message}
            onClose={closeAlert}
          />
        )}
        <h3 className="text-lg font-semibold">Listing {groupIndex + 1}</h3>
        {canDuplicate && (
          <Button
            label="Generate New Version"
            color="primary"
            disabled={!canDuplicate}
            className={`py-2 md:px-10 px-5 rounded-sm md:text-base text-sm ${
              !canDuplicate
                ? 'bg-[#808080] text-[#B7B7B7]'
                : 'border-etsyPrimary border bg-white text-etsyPrimary'
            }`}
            onClick={handleDuplicate}
          />
        )}
      </div>
      {activeListings.map((listing, index) => (
        <SessionHistoryItem
          key={listing.id}
          {...listing}
          index={index}
          onDelete={onDelete}
        />
      ))}
    </div>
  );
};

const SessionHistory: React.FC = () => {
  useAxiosInterceptors();
  const [groupedListings, setGroupedListings] = useState<{
    [key: string]: GroupedListingMetadata;
  }>({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchListings = async () => {
      setIsLoading(true);
      try {
        const response = await axiosEtsyInstance.get('/listing');
        const allListings = response.data;

        const grouped = allListings.reduce(
          (
            acc: { [key: string]: GroupedListingMetadata },
            listing: SessionHistoryItemProps
          ) => {
            const key = listing.session_id;
            if (!acc[key]) {
              acc[key] = {
                listings: [],
                activeDuplicateId: null,
                canDuplicate: true,
              };
            }
            acc[key].listings.push(listing);
            return acc;
          },
          {}
        );

        // Process each group to determine duplicate chain and buttons
        Object.keys(grouped).forEach(key => {
          const group = grouped[key];

          // Sort by version number to ensure correct chain order
          group.listings.sort(
            (a: SessionHistoryItemProps, b: SessionHistoryItemProps) =>
              a.version - b.version
          );

          const original = group.listings.find(
            (l: SessionHistoryItemProps) => !l.parent_listing_id
          );

          if (original) {
            const firstChild = group.listings.find(
              (l: SessionHistoryItemProps) =>
                l.parent_listing_id === original.id
            );

            const secondChild = firstChild
              ? group.listings.find(
                  (l: SessionHistoryItemProps) =>
                    l.parent_listing_id === firstChild.id
                )
              : null;

            if (secondChild) {
              group.canDuplicate = false;
              group.activeDuplicateId = null;
            } else if (firstChild) {
              group.canDuplicate = true;
              group.activeDuplicateId = firstChild.id;
            } else {
              group.canDuplicate = true;
              group.activeDuplicateId = original.id;
            }
          }
        });
        setGroupedListings(grouped);
      } catch (err) {
        setError('Error loading listings');
      } finally {
        setIsLoading(false);
      }
    };

    fetchListings();
  }, []);

  const handleDelete = async (id: string) => {
    try {
      await axiosEtsyInstance.post(`/listing/${id}/archive`);
      setGroupedListings(prevGrouped => {
        const newGrouped = { ...prevGrouped };

        Object.keys(newGrouped).forEach(sessionId => {
          const group = newGrouped[sessionId];
          const listingIndex = group.listings.findIndex(l => l.id === id);

          if (listingIndex !== -1) {
            // Update the listing to be archived
            group.listings[listingIndex] = {
              ...group.listings[listingIndex],
              is_archived: true,
            };

            const original = group.listings.find(l => !l.parent_listing_id);
            if (original) {
              const firstChild = group.listings.find(
                l => l.parent_listing_id === original.id
              );
              const secondChild = firstChild
                ? group.listings.find(
                    l => l.parent_listing_id === firstChild.id
                  )
                : null;

              if (secondChild) {
                group.canDuplicate = false;
                group.activeDuplicateId = null;
              } else if (firstChild) {
                group.canDuplicate = true;
                group.activeDuplicateId = firstChild.id;
              } else {
                group.canDuplicate = true;
                group.activeDuplicateId = original.id;
              }
            }
          }
        });
        return newGrouped;
      });
    } catch (error) {
      console.error('Error deleting listing:', error);
    }
  };

  return (
    <div className="pb-5 w-full">
      <Card
        heading="History"
        subHeading="Recently generated listings will show here"
      >
        {isLoading && (
          <div className="flex items-center justify-center">
            <Loader />
          </div>
        )}

        {error && <p>{error}</p>}

        {!isLoading && !error && (
          <>
            {Object.keys(groupedListings).length ? (
              Object.entries(groupedListings).map(
                ([sessionId, groupData], groupIndex) => (
                  <ListingGroup
                    key={sessionId}
                    sessionId={sessionId}
                    groupData={groupData}
                    groupIndex={groupIndex}
                    onDelete={handleDelete}
                  />
                )
              )
            ) : (
              <p>No listings generated yet</p>
            )}
          </>
        )}
      </Card>
    </div>
  );
};

export default SessionHistory;
